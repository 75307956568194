
/* Run functions on window.load */
$(window).load(function() {

});

/* Run functions on document.ready */
$(document).ready(function(){
  /* Admin bar */
  adminBar();

  /* Declare custom path */
  var cdnPath = 'https://cdn.adnetcms.com/lib';
  var themePath = '/_themes/design2015';

  /* Form validation */
  var forms = $('form#contact-compact, form#contact');
  if (forms.length){
    $.when(
       $.getScript( cdnPath + "/parsley/2.0.7/parsley.min.js" ),
       $.Deferred(function( deferred ){
          $( deferred.resolve );
       })
    ).done(function(){
      forms.parsley();
    });
  }

  /* Main navigation */
  var options = {
    delay: 300
  };
  $('ul.sf-menu').superfish(options);

  /* Apply fitvids to slideshare iframe */
  if ($('.presentations-page').length){
    $('#page-content').fitVids();
  }

  /* Format tables and lists */
  var mainDiv = $('div#page-content');
  if (mainDiv.length){
    // Convert tables to uikit style table
    mainDiv.find('table').not('.exception').each(function(){
      var table = $(this);
      table.wrap('<div class="uk-overflow-container" />');
      table.addClass('uk-table uk-table-striped');

      // Center Table headers
      table.find('th').each(function (){
        $(this).addClass('uk-text-center');
      });

      // Align content with numbers
      table.find('td').each(function(){
        if ($(this).html().match(/^[\d,\$\.\sUS\(\)]*$/)) {
          $(this).wrapInner('<span class="numbers-align-center" />');
        }
        if ($(this).html().match(/^[\d,\$\.%]*$/)) {
          $(this).wrapInner('<span class="numbers-align-right" />');
        }

      });
    });

    // Convert lists to uikit style list
    mainDiv.find('ul').not('.exception').each(function(){
      $(this).addClass('uk-list uk-list-line');
    });
  }

  /* Subscription form */
  $('.sendgrid-subscription-widget').on('sent', function () {
    $(this).addClass('loading')
           .append('<i class="uk-icon-spinner uk-icon-spin uk-icon-medium"></i>');
    $(this).find('input[type=submit]').attr('disabled', 'disabled');

    $('.sendgrid-subscription-widget').on('success error', function () {
        $(this).removeClass('loading')
        $(this).find('i').remove();
        $(this).find('input[type=email]').val('');
        $(this).find('input[type=submit]').removeAttr('disabled');
    });
  });

  /* Front map settings */
  if ($('.map').length > 0){
    $('.map').each(function(){
      var gMap = $(this);

      /* Retrieve meta information from DOM data tag */
      var mapID = gMap.attr('id');
      var mapDOM = $('#' + mapID);
      var mapLat = gMap.data('lat');
      var mapLng = gMap.data('lng');
      var mapZoom = gMap.data('mapzoom');
      var mapName = gMap.data('mapname');
      var mapType = gMap.data('maptype');

      /* Initialize Google Map */
      setGMap(mapID, mapDOM, mapLat, mapLng, mapZoom, mapName, mapType);
    });
  }

 /* Tabs
  * Styling: uiKit tab component
  * Logic: easyTabs
  */

  /* Tabs settings */
  var tabs = $('.uk-tab');
  var itemDiv = $('#item-sort');
  var tabContainer = $('.tab-container');

  /* Hide tabs if empty */
  if (tabs.children().length == 0){
    tabs.hide();
  } else {
    $.when(
      $.getScript( "//cdnjs.cloudflare.com/ajax/libs/jquery.easytabs/3.2.0/jquery.easytabs.min.js" ),
      $.Deferred(function( deferred ){
        $( deferred.resolve );
      })
    ).done(function(){
      tabContainer.easytabs({
        animationSpeed: 'fast',
        tabActiveClass: 'uk-active',
        defaultTab: 'li#def'
      });
      tabs.fadeIn(500);
      $('.item-group').show(300);

      /* Stock chart only, select 1 yr chart by default */
      //tabContainer.easytabs('select', '#one_yr');
    });
  }
});